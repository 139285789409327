html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Dosis, sans-serif;
  font-weight: lighter;
}

.logo-link {
  color: inherit;
  text-decoration: none;
}

main {
  position: relative;

  min-height: 100vh;
  /* background: #07122f55; */
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  color: white;
}

main video {
  background: black url(../img/chaosidebg.jpg?as=webp) no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

header {
  color: white;
  position: fixed;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}

header.dark {
  color: #0d1e49;
}

h1 {
  font-weight: 200;
  font-size: 30px;
  margin: 0;
}

header h1 {
  position: relative;
  text-indent: 80px;
  line-height: 45px;
}

header h1::before {
  background-color: transparent;
  content: "";
  position: absolute;
  left: 0.5em;
  width: 48px;
  height: 48px;
  background: url(../img/logo.png);
}

header nav {
  display: grid;
  grid-auto-flow: column;
  gap: 3em;
  align-content: flex-start;
}

header.dark nav a {
  color: #0d1e49;
  background: linear-gradient(#0d1e49, #0d1e49);
  background-size: 0% 100%;
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: bottom left;
  transition: all 0.3s;
}

header.dark nav a:hover {
  color: white;
  background-size: 100% 100%;
}

header nav a {
  text-decoration: none;
  color: white;
  font-size: 25px;
  font-weight: lighter;
  background-clip: text;
  background: linear-gradient(white, white);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: bottom left;
  transition: background 0.3s;
  padding: 0 0.2em 0 0;
}

header nav a:hover {
  background-size: 100% 100%;
  color: #0d1e49;
}

.hero {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.hero h2 {
  background: white;
  color: black;
  padding: 0 0.2em;
  font-size: min(10vw, 48px);
  font-weight: lighter;
  margin: 0;
}

.hero p {
  font-size: 24px;
  margin: 0;
  background: black;
  padding: 0 0.2em;
}

#cursor {
  position: fixed;
  align-self: center;
  margin-bottom: 5rem;
}

@keyframes wheel {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15%);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#cursor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes fade-in {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

#cursor {
  /* animation: 1s fade-in forwards; */
}

#cursor .wheel {
  animation: 1.5s infinite ease-in wheel;
}

#cursor p {
  text-transform: uppercase;
  font-size: 0.8em;
  opacity: 0.4;
}

#vision {
  overflow: hidden;
  min-height: 100vh;
  background: white 50% 100% / cover url(../img/vision-bg.webp);
  background-attachment: fixed;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#vision h2 {
  margin-left: -12.5rem;
  font-family: Dosis, Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

#vision h2 span {
  display: block;
  font-weight: bold;
  color: #0635c1;
  font-size: 4rem;
  text-transform: uppercase;
  font-family: "Reem Kufi", Arial, Helvetica, sans-serif;
}

#vision .boxes {
  margin-left: -1.5rem;
  max-width: 560px;
  width: 100%;
}

#vision .boxes p {
  margin: 0;
}

#vision .boxes p:not(:last-child) {
  margin-bottom: 1rem;
}

#vision .dark {
  background: #0d1e49;
  background: rgba(0, 0, 40, 0.8);
  backdrop-filter: blur(5px);
  color: white;
  padding: 2rem;
  font-size: 26px;
}

#vision .light {
  color: #fff;
  background: rgba(0, 0, 255, 0.7);
  backdrop-filter: blur(5px);
  position: relative;
  left: 3rem;
  padding: 2rem;
  font-size: 26px;
}

#contact {
  background: white;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 7rem 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contact h1 {
  align-self: center;
  margin-bottom: 3rem;
  font-weight: bold;
  font-family: "Reem Kufi";
  color: #03f;
  font-size: 3rem;
}

#contact-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 2em;
  width: 100%;
  max-width: 600px;
}

#contact-form input,
#contact-form textarea {
  box-sizing: border-box;
  font-family: Dosis, Arial, Helvetica, sans-serif;
  background: #f9f9f9;
  border: none;
  border-bottom: 2px solid #999;
  padding: 0.5em 0.7em;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  outline: none;
}

#contact-form input:focus,
#contact-form textarea:focus {
  border-color: #0af;
}

#contact-form input {
  width: 100%;
  max-width: 300px;
}

#contact-form textarea {
  max-width: 600px;
  width: 100%;
  min-height: 200px;
  line-height: 2rem;
}

#contact-form button {
  align-self: flex-end;
  border: none;
  border-radius: 0;
  font-size: 1.2rem;
  font-family: Dosis, Arial, Helvetica, sans-serif;
  padding: 0.5em 1em;
  transition: 0.3s;
  color: white;
  background-color: transparent;
  background-image: linear-gradient(#0d1e49, #0d1e49);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100% 3px;
  cursor: pointer;
  color: #0d1e49;
  outline: 2px solid white;
}

#contact-form button:focus {
  outline: 2px solid #0af;
}

#contact-form button:hover {
  color: white;
  background-size: 100% 100%;
}

#contact-form .sent {
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
  color: steelblue;
  font-weight: bold;
}
