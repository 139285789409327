html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Dosis, sans-serif;
  font-weight: lighter;
}

.logo-link {
  color: inherit;
  text-decoration: none;
}

main {
  min-height: 100vh;
  color: #fff;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: relative;
}

main video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  background: #000 url("chaosidebg.97d6a18e.webp") 0 0 / cover no-repeat;
  position: fixed;
  top: 0;
}

header {
  color: #fff;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  transition: all .3s;
  display: flex;
  position: fixed;
}

header.dark {
  color: #0d1e49;
}

h1 {
  margin: 0;
  font-size: 30px;
  font-weight: 200;
}

header h1 {
  text-indent: 80px;
  line-height: 45px;
  position: relative;
}

header h1:before {
  content: "";
  width: 48px;
  height: 48px;
  background: url("logo.92afb47f.png");
  position: absolute;
  left: .5em;
}

header nav {
  grid-auto-flow: column;
  align-content: flex-start;
  gap: 3em;
  display: grid;
}

header.dark nav a {
  color: #0d1e49;
  background: linear-gradient(#0d1e49, #0d1e49) 0 100% / 0% 100% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  transition: all .3s;
}

header.dark nav a:hover {
  color: #fff;
  background-size: 100% 100%;
}

header nav a {
  color: #fff;
  background: linear-gradient(#fff, #fff) 0 100% / 0% 100% no-repeat;
  padding: 0 .2em 0 0;
  font-size: 25px;
  font-weight: lighter;
  text-decoration: none;
  transition: background .3s;
}

header nav a:hover {
  color: #0d1e49;
  background-size: 100% 100%;
}

.hero {
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.hero h2 {
  color: #000;
  background: #fff;
  margin: 0;
  padding: 0 .2em;
  font-size: min(10vw, 48px);
  font-weight: lighter;
}

.hero p {
  background: #000;
  margin: 0;
  padding: 0 .2em;
  font-size: 24px;
}

#cursor {
  align-self: center;
  margin-bottom: 5rem;
  position: fixed;
}

@keyframes wheel {
  0% {
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(15%);
  }

  100% {
    opacity: 0;
  }
}

#cursor {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#cursor .wheel {
  animation: 1.5s ease-in infinite wheel;
}

#cursor p {
  text-transform: uppercase;
  opacity: .4;
  font-size: .8em;
}

#vision {
  min-height: 100vh;
  background: #fff url("vision-bg.e4892225.webp") 50% 100% / cover fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

#vision h2 {
  margin-left: -12.5rem;
  font-family: Dosis, Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

#vision h2 span {
  color: #0635c1;
  text-transform: uppercase;
  font-family: Reem Kufi, Arial, Helvetica, sans-serif;
  font-size: 4rem;
  font-weight: bold;
  display: block;
}

#vision .boxes {
  max-width: 560px;
  width: 100%;
  margin-left: -1.5rem;
}

#vision .boxes p {
  margin: 0;
}

#vision .boxes p:not(:last-child) {
  margin-bottom: 1rem;
}

#vision .dark {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #fff;
  background: #000028cc;
  padding: 2rem;
  font-size: 26px;
}

#vision .light {
  color: #fff;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #0000ffb3;
  padding: 2rem;
  font-size: 26px;
  position: relative;
  left: 3rem;
}

#contact {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7rem 3rem;
  display: flex;
}

#contact h1 {
  color: #03f;
  align-self: center;
  margin-bottom: 3rem;
  font-family: Reem Kufi;
  font-size: 3rem;
  font-weight: bold;
}

#contact-form {
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  align-items: start;
  margin-top: 2em;
  display: flex;
}

#contact-form input, #contact-form textarea {
  box-sizing: border-box;
  background: #f9f9f9;
  border: none;
  border-bottom: 2px solid #999;
  outline: none;
  margin-bottom: 1rem;
  padding: .5em .7em;
  font-family: Dosis, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}

#contact-form input:focus, #contact-form textarea:focus {
  border-color: #0af;
}

#contact-form input {
  width: 100%;
  max-width: 300px;
}

#contact-form textarea {
  max-width: 600px;
  width: 100%;
  min-height: 200px;
  line-height: 2rem;
}

#contact-form button {
  color: #fff;
  cursor: pointer;
  color: #0d1e49;
  background-color: #0000;
  background-image: linear-gradient(#0d1e49, #0d1e49);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  border: none;
  border-radius: 0;
  outline: 2px solid #fff;
  align-self: flex-end;
  padding: .5em 1em;
  font-family: Dosis, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  transition: all .3s;
}

#contact-form button:focus {
  outline: 2px solid #0af;
}

#contact-form button:hover {
  color: #fff;
  background-size: 100% 100%;
}

#contact-form .sent {
  text-align: center;
  width: 100%;
  color: #4682b4;
  font-size: 1.2rem;
  font-weight: bold;
}

/*# sourceMappingURL=index.f9f4fa8d.css.map */
